import DefaultConfig from './default.js';
import utils from 'client-services/lib/utils-service.js';

var conf = {
	services: {
		google: {
			authKey: '990916630270-3u52a5h7nl5kp9rnlrb85gev24f534kv.apps.googleusercontent.com'
		},
		api: {
			basePath: 'api.dev.cupixel.com'
		},
		logger: {
			level: 'trace'
		}
	},
	data: {
		slsBaseUrl: 'https://sls.dev.cupixel.com/ecomm/',
		ccBaseUrl: 'https://cc.dev.cupixel.com/'
	}
};

window.cpxConfig = utils.extend(true, {}, DefaultConfig, conf);
