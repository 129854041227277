var DefaultConfig = {
	app: {
		menu: [
			{name: 'users', text: 'Users', icon: 'groups', items: [
				{ name: 'users', text: 'App Users', icon: 'phone_iphone', path: '/users/app' },
				{ name: 'boUsers', text: 'BO Users', icon: 'apartment', path: '/users/bo' }
			]},
			{name: 'membership', text: 'Membership', icon: 'loyalty', items: [
				{ name: 'memberships', text: 'Memberships', icon: 'card_membership', outlined: true, path: '/memberships' },
				{ name: 'personal-promos', text: 'Personal Promos', icon: 'support_agent', path: '/promo/personal' },
				{ name: 'mkt-promos', text: 'Shared Promos', icon: 'redeem', path: '/promo/shared' },
				{name: 'promo-search', text: 'Search Promo', icon: 'search', path: '/promo/search'},
			]},
			{name: 'projects', text: 'Projects', icon: 'format_paint', path: '/projects'},
			{name: 'store', text: 'Store', icon: 'store', items: [
				{name: 'new-order', text: 'New Order', icon: 'add', path: '/order/new'},
				{name: 'orders', text: 'Orders', icon: 'attach_money', path: '/orders'},
				{name: 'coupons', text: 'Coupons', icon: 'money', path: '/store/coupons'},
				{name: 'pos', text: 'POS', icon: 'storefront', path: '/store/pos'},
				{name: 'import', text: 'Import', icon: 'import_export', path: '/store/import/orders'},
				{name: 'import-shipments', text: 'Import Shipments', icon: 'local_shipping', path: '/store/import/shipments'}
			]},
			{name: 'reports', text: 'Reports', icon: 'content_paste', items: [
				{ name: 'sales', text: 'Sales', icon: 'attach_money', path: '/reports/sales' },
				{ name: 'shipping', text: 'Shipping', icon: 'local_shipping', path: '/reports/shipping' },
				{ name: 'buyers', text: 'Buyers', icon: 'shop_two', path: '/reports/buyers' }
			]},
			{name: 'dashboards', text: 'Dashboards', icon: 'dashboard', items: [
				{ name: 'dashboard', text: 'Store', icon: 'shopping_cart', path: '/dashboard/sales' },
				{ name: 'marketing', text: 'Marketing', icon: 'insights', path: '/dashboard/marketing' },
			]},
			{name: 'tools', text: 'Tools', icon: 'build', items: [
				{name: 'push-notifications', text: 'Push Notifications', icon: 'campaign', path: '/notifications'},
				{name: 'experiments', text: 'Experiments', icon: 'colorize', path: '/experiments'},
			]}
		]
	},
	services: {
		google: {
			authKey: ''
		},
		logger: {
			length: 1000
		},
		audit: {
			strict: true, // Whether audit has to pass
			severities: {}, // Dictionary represents for each error its severity (overrides defaults that in from audit-errors.js)
			rules: {
				colors: {
					maxColors: 30, // Maximum number of unique colors allowed
					maxPaint: 30 // Then percentage of maximum paint allowed per color
				}
			}
		},
		gallery: {
			thumbnails: {
				small: 't_thumb_s',
				medium: 't_thumb_med'
			}
		},
		palette: {
			colorSpace: 'acrylic'
		}
	},
	data: {
		showAllUsers: false,
		showAllImages: false,
		showDeletedImages: false,
		cloudinaryBaseUrl: 'https://api.cloudinary.com/v1_1/cupixel',
		amplitudeSearchLink: 'https://analytics.amplitude.com/cupixel/project/400839/search/',
		slsBaseUrl: '',
		ccBaseUrl: '',
		lookerDashboards: {
			marketing: ''
		}
	}
};

export default DefaultConfig;
